import React from "react";
// Tools
import styled from "styled-components";
import theme from "theme";
// components
// import Login from "containers/login";
// import Signup from "containers/signup";
import A2Logo from 'components/A2Logo';

const Background = styled.main`
  background: ${theme.ui.silver};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const ReadingSpace = styled.div`
  width: 100%;
  max-width: 1060px;
  height: 100%;
  display: flex;
  align-items: center;

  @media(max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10%;
    ${props => props.selected && `margin-top: 0;`}
  }
`;

const HalfBloc = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;

  @media(max-width: 768px) {
    width: 100%;
    align-items: center;

    &.first-child {
      ${props => props.selected && `display: none;`}
    }
  }
`;

const SloganAll = styled.div`
  display: flex;
  font-family: 'Raleway', sans-serif;
`;

const SloganAst = styled.div`
  display: flex;
  font-size: 34px;
  padding: 0 4px 0 80px;
  color: ${theme.blue};
`;

const SloganText = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 200;
  font-style: italic;
`;

const GatherTitle = styled.h2`
  font-family: 'Raleway', sans-serif;
  @media(max-width: 768px) {
    margin: 30px 20px;
  }
`;

const LoginPage = ({children, selected}) => {

  return (
    <React.StrictMode>
      <Background>
        <ReadingSpace selected={selected}>
          <HalfBloc className="first-child" selected={selected}>
            <A2Logo />
            <SloganAll>
              <SloganAst>
                *
              </SloganAst>
              <SloganText>
                le vote accessible
              </SloganText>
            </SloganAll>
            <GatherTitle>Avec A2Display, planifier et organiser vos votes.</GatherTitle>
          </HalfBloc>
          <HalfBloc>
            {children}
          </HalfBloc>
        </ReadingSpace>
      </Background>
    </React.StrictMode>
  )
}

export default LoginPage;